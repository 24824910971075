import { createApp } from 'vue';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';
import App from './App.vue';
import router from './router';
import store from './store';

import 'primevue/resources/themes/lara-dark-indigo/theme.css'; // PrimeVue theme
import 'primevue/resources/primevue.min.css'; // Core PrimeVue styles
import './assets/tailwind.css';
import 'primeicons/primeicons.css'; // PrimeIcons
import 'primeflex/primeflex.css'; // PrimeFlex for layout utilities
import '@mdi/font/css/materialdesignicons.css';

// PrimeVue Components
import PrimeCard from 'primevue/card';
import PrimeInputText from 'primevue/inputtext';
import PrimeButton from 'primevue/button';
console.log("test");
const app = createApp(App);

app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);
app.directive('tooltip', Tooltip); // Register the Tooltip directive

// Register PrimeVue components globally
app.component('PrimeCard', PrimeCard);
app.component('PrimeInputText', PrimeInputText);
app.component('PrimeButton', PrimeButton);

app.use(router);
app.use(store);

app.mount('#app');
