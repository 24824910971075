<template>
  <footer class="footer">
    <a href="#" @click.prevent="openHelpRequest()">
      <div class="content">
        Need Help?
      </div>
    </a>
    <Dialog v-model:visible="visible" modal header="Need Help?" :style="{ width: '50rem' }">
      <span class="block mb-5">Please fill out the form below with as much detail as possible to ensure
        the most efficient resolution to your question or issue. Upon submission, the DIGIFIT support team will get back
        to you within 1 business day (usually sooner).</span>
      <div class="card flex flex-column md:flex-row gap-3">
        <InputGroup>
          <FloatLabel>
            <label for="name">Name</label>
            <InputText id="name" v-model="form.name" @blur="validateField('name')" :invalid=invalidFields.name required />
          </FloatLabel>
        </InputGroup>
        <InputGroup>
          <FloatLabel>
            <label for="contact">Phone Number or Email Address</label>
            <InputText id="contact" v-model="form.contact" @blur="validateField('contact')" :invalid=invalidFields.contact required />
          </FloatLabel>
        </InputGroup>
      </div>
      <div class="mt-5">
        <InputGroup>
          <FloatLabel>
            <label for="issue">Please describe your question or issue</label>
            <Textarea :style="{ borderRadius: '6px'}" id="issue" v-model="form.issue" @blur="validateField('issue')" :invalid=invalidFields.issue required />
          </FloatLabel>
        </InputGroup>
      </div>

      <div class="flex justify-content-end gap-2 mt-3">
        <Button label="Submit" severity="secondary" @click="SubmitForm" :disabled="!isFormValid"></Button>
        <Button type="button" label="Cancel" severity="secondary" @click="visible = false"></Button>
      </div>
    </Dialog>
    <Dialog v-model:visible="confirmationVisible" modal header="Request Sent">
      <span class="block mb-5">Thank you. Your message was delivered, and one of our team members will reach out to you shortly.</span>
      <div class="flex justify-content-end gap-2 mt-3">
        <Button label="Okay" severity="secondary" @click="confirmationVisible = false"></Button>
      </div>
    </Dialog>
  </footer>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputGroup from 'primevue/inputgroup';
import InputText from 'primevue/inputtext';
import FloatLabel from 'primevue/floatlabel';
import Textarea from 'primevue/textarea';
import apiCall from '@/utils/api';

export default defineComponent({
  name: 'FooterComp',
  components: {
    Dialog,
    Button,
    InputGroup,
    InputText,
    FloatLabel,
    Textarea
  },
  setup() {
    const store = useStore();
    const visible = ref(false);
    const confirmationVisible = ref(false);
    const form = ref({
      name: '',
      contact: '',
      issue: ''
    });

    const initialFormState = {
      name: '',
      contact: '',
      issue: ''
    };

    const invalidFields = ref({
      name: false,
      contact: false,
      issue: false
    });

    const validateField = (field: keyof typeof form.value) => {
      invalidFields.value[field] = !form.value[field];
    };

    const isFormValid = computed(() => {
      return form.value.name && form.value.contact && form.value.issue;
    });

    const openHelpRequest = () => {
      form.value = { ...initialFormState };
      visible.value = true;
    }

    const SubmitForm = async () => {
      try {
        const endpoint = '/api/Help';
        const requestBody = {
          ...form.value,
          Username: store.state.auth.username,
          SiteID: store.state.auth.siteID
        };
        await apiCall(endpoint, requestBody);
        visible.value = false;
        confirmationVisible.value = true;
        form.value = { ...initialFormState }; // Reset form fields
        Object.keys(invalidFields.value).forEach(key => {
          invalidFields.value[key as keyof typeof form.value] = false;
        });
      } catch (error) {
        alert('There was an error submitting your request. Please try again later.');
      }
    };

    return {
      visible,
      confirmationVisible,
      form,
      invalidFields,
      validateField,
      openHelpRequest,
      isFormValid,
      SubmitForm
    };
  }
});
</script>

<style scoped>
.footer {
  width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1rem 0;
  position: fixed;
  bottom: 0;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
}

.p-text-secondary {
  color: #6c757d;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mt-3 {
  margin-top: 1rem;
}
</style>
