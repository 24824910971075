import { createStore } from 'vuex';
import auth, { AuthState } from './modules/auth';
import site, { SiteState } from './modules/site';

export interface RootState {
  auth: AuthState;
  site: SiteState;
}

const store = createStore<RootState>({
  modules: {
    auth,
    site,
  }
});

export default store;
