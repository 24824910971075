<template>
  <div>
    <div v-if="registrationSuccess">
      <p>Registration Successful!</p>
      <p>Click <span class="link" @click="switchToLogin">here</span> to log in.</p>
    </div>
    <div v-else>
      <form @submit.prevent="handleSubmit">
        <div class="my-3">
          <InputGroup class="">
        <InputGroupAddon>
            <i class="pi pi-user"></i>
        </InputGroupAddon>
        <InputText
            v-model="credentials.username"
            placeholder="Username"
            @blur="checkUsernameAvailability"
            @input="clearUsernameError"
          />
        </InputGroup>
        <small  v-if="showUsernameError && usernameError" class="error-message">{{ usernameError }}</small >
        </div>
        <div class="my-3">
          <InputGroup>
        <InputGroupAddon>
          <i class="pi pi-lock"></i>
        </InputGroupAddon>
        <Password
            type="password"
            v-model="credentials.password"
            placeholder="Password"
            :feedback="false"
            toggleMask
            @blur="validatePassword"
            @input="clearPasswordError"
          />
        </InputGroup>
        <small v-if="showPasswordError && passwordError" class="error-message">{{ passwordError }}</small>
        </div>
        <div class="my-3">
          <InputGroup>
        <InputGroupAddon>
          <i class="pi pi-key"></i>
        </InputGroupAddon>
        <InputText
            v-model="credentials.registrationKey"
            placeholder="Registration Key"
            @blur="validateRegistrationKey"
            @input="clearRegistrationKeyError"
          />
        </InputGroup>
        <small v-if="showRegistrationKeyError && registrationKeyError" class="error-message">{{ registrationKeyError }}</small>
        </div>
        <Button
          type="submit"
          label="Create Account"
          class="p-mt-2"
          :disabled="usernameError || !credentials.username || passwordError || !credentials.password || registrationKeyError || !credentials.registrationKey"
        />
      </form>
      <p class="toggle-link" @click="switchToLogin">Already have an account? <span class="link">Log in here!</span></p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';
  import InputGroup from 'primevue/inputgroup';
  import InputGroupAddon from 'primevue/inputgroupaddon';
import axios from 'axios';

export default defineComponent({
  name: 'RegisterWidget',
  components: {
    InputText,
    InputGroup,
    InputGroupAddon,
    Password,
    Button,
  },
  setup(_, { emit }) {
    const store = useStore();
    const credentials = ref({ username: '', password: '', registrationKey: '' });
    const error = ref<string>('');
    const usernameError = ref<string>('');
    const passwordError = ref<string>('');
    const registrationKeyError = ref<string>('');
    const registrationSuccess = ref<boolean>(false);
    const showUsernameError = ref<boolean>(false);
    const showPasswordError = ref<boolean>(false);
    const showRegistrationKeyError = ref<boolean>(false);

    const handleSubmit = async () => {
      try {
        await store.dispatch('auth/register', {
          username: credentials.value.username,
          password: credentials.value.password,
          registrationKey: credentials.value.registrationKey,
        });
        registrationSuccess.value = true; // Show registration success message
      } catch (err: any) {
        error.value = (err as Error).message || 'Registration failed'; // Handle error, show message to user
      }
    };

    const checkUsernameAvailability = async () => {
      if (!credentials.value.username) {
        return; // Don't validate if username is empty
      }

      showUsernameError.value = true;
      try {
        await axios.get(`https://nl-ioe-dev.azurewebsites.net/api/Users/CheckUsername`, { params: { username: credentials.value.username } });
        usernameError.value = '';
      } catch (err: any) {
        if (err.response && err.response.status === 409) {
          usernameError.value = 'This username is taken.';
        } else {
          usernameError.value = 'Error checking username.';
        }
      }
    };

    const validatePassword = () => {
      if (!credentials.value.password) {
        return; // Don't validate if password is empty
      }

      showPasswordError.value = true;
      const password = credentials.value.password;
      if (password.length < 8) {
        passwordError.value = 'Password must be at least 8 characters long.';
      } else if (!/[A-Z]/.test(password)) {
        passwordError.value = 'Password must contain at least one uppercase letter.';
      } else if (!/[a-z]/.test(password)) {
        passwordError.value = 'Password must contain at least one lowercase letter.';
      } else if (!/[0-9]/.test(password)) {
        passwordError.value = 'Password must contain at least one digit.';
      } else if (!/[\W_]/.test(password)) {
        passwordError.value = 'Password must contain at least one special character.';
      } else {
        passwordError.value = '';
      }
    };

    const clearUsernameError = () => {
      usernameError.value = '';
      showUsernameError.value = false;
    };

    const clearPasswordError = () => {
      passwordError.value = '';
      showPasswordError.value = false;
    };

    const validateRegistrationKey = () => {
      if (!credentials.value.registrationKey) {
        return; // Don't validate if registration key is empty
      }

      showRegistrationKeyError.value = true;
      const registrationKey = credentials.value.registrationKey;
      const keyPattern = /^[A-Za-z0-9]{8}$/;
      if (!keyPattern.test(registrationKey)) {
        registrationKeyError.value = 'Registration Key is invalid.';
      } else {
        registrationKeyError.value = '';
      }
    };

    const clearRegistrationKeyError = () => {
      registrationKeyError.value = '';
      showRegistrationKeyError.value = false;
    };

    const switchToLogin = () => {
      emit('toggle');
      registrationSuccess.value = false;
      credentials.value = { username: '', password: '', registrationKey: '' };
      error.value = '';
      usernameError.value = '';
      passwordError.value = '';
      registrationKeyError.value = '';
      showUsernameError.value = false;
      showPasswordError.value = false;
      showRegistrationKeyError.value = false;
    };

    return {
      credentials,
      error,
      usernameError,
      passwordError,
      registrationKeyError,
      showUsernameError,
      showPasswordError,
      showRegistrationKeyError,
      registrationSuccess,
      handleSubmit,
      checkUsernameAvailability,
      clearUsernameError,
      clearPasswordError,
      clearRegistrationKeyError,
      validatePassword,
      validateRegistrationKey,
      switchToLogin,
    };
  },
});
</script>

<style scoped>
.p-field {
  margin-bottom: 1rem;
}

.error-message {
  color: red;
  text-align: center;
}

.toggle-link {
  color: #f6f6f6;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
}

.link {
  text-decoration: underline;
}
</style>

