<template>
  <div class="login-container">
    <Card class="login-card">
      <template #header>
        <img alt="user header" src="../assets/digifitLogoMd.png" />
    </template>
      <template #content>
        <LoginWidget v-if="!isSignUp" @toggle="toggleSignUp" />
        <RegisterWidget v-else @toggle="toggleSignUp" />
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Card from 'primevue/card';
import LoginWidget from '../components/LoginWidget.vue';
import RegisterWidget from '../components/RegisterWidget.vue';

export default defineComponent({
  name: 'LoginPage',
  components: {
    Card,
    LoginWidget,
    RegisterWidget,
  },
  setup() {
    const isSignUp = ref<boolean>(false);

    const toggleSignUp = () => {
      isSignUp.value = !isSignUp.value;
    };

    return {
      isSignUp,
      toggleSignUp,
    };
  },
});
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-card {
  width: 400px;
  padding: 2rem;
  overflow: hidden;
}
</style>
