<template>
  <div class="rounded-2xl px-3 pt-3 component-bg borderless-paginator">
    <div class="card">
      <Toolbar>
        <template #start>
          <div class="card flex flex-wrap gap-3 p-fluid">
            <div class="flex-auto">
              <label for="usersearch" class="font-bold block mb-1"> User Search </label>
              <IconField inputId="usersearch">
                <InputIcon>
                  <i class="pi pi-search" />
                </InputIcon>
                <InputText placeholder="Search User ID" v-model="searchQuery" />
              </IconField>
            </div>
            <div class="flex-auto ml-3">
              <label for="icondisplay" class="font-bold block mb-1"> Results Since: </label>
              <Calendar class="" v-model="resultsSinceDate" showIcon iconDisplay="input" inputId="icondisplay" />
            </div>
          </div>
        </template>
        <template #end>
          <ConfirmDialog></ConfirmDialog>
          <Button label="Delete" icon="pi pi-trash" rounded @click="confirmDeleteSelected"
            :disabled="!selectedProduct || !selectedProduct.length" />
        </template>
      </Toolbar>
    </div>
    <div v-if="filteredConsumers.length > 0">
      <DataTable :value="transformedConsumers" v-model:selection="selectedProduct" tableStyle="min-width: 50rem"
        :rows="8" :paginator="true" selectionMode="multiple" striped-rows>
        <Column field="ptId" header="User ID"></Column>
        <Column field="datetime" header="Date/Time"></Column>
        <Column field="deviceInfo" header="Device"></Column>
        <Column field="symptoms" header="Symptoms">
          <template #body="slotProps">
            <span v-if="slotProps.data.symptoms.length > 0" @mouseenter="showOverlay($event, slotProps.data.symptoms)"
              @mouseleave="hideOverlay">
              Yes ({{ slotProps.data.symptoms.length }})
            </span>
            <span v-else>None</span>
          </template>
        </Column>
        <Column field="digUser" header="Dig. User"></Column>
        <Column field="learnMore" header="Learn More"></Column>
        <Column field="indication" header="Rating">
          <template #body="slotProps">
            <div :class="stockClass(slotProps.data)">
              {{ slotProps.data.indication }}
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <h2 v-else>
      No new users {{ formattedSearchDate }}
    </h2>

    <OverlayPanel ref="overlayPanel" :dismissable="true">
      <div class="chips-vertical-container">
        <Chip v-for="(chip, index) in overlayChips" :key="index" :label="chip" class="vertical-chip" />
      </div>
    </OverlayPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Chip from 'primevue/chip';
import OverlayPanel from 'primevue/overlaypanel';
import Button from 'primevue/button';
import Toolbar from 'primevue/toolbar';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import ConfirmDialog from 'primevue/confirmdialog';
import Calendar from 'primevue/calendar';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import apiCall from '../utils/api'; // Import the apiCall utility

interface Device {
  deviceID: string;
  alias: string | null;
  description: string;
}

interface Consumer {
  consumerID: string;
  identifier: string;
  created: string;
  response: string;
  deviceID: string;
}

export default defineComponent({
  name: 'CustomDataTable',
  components: {
    DataTable,
    Column,
    Chip,
    OverlayPanel,
    Button,
    Toolbar,
    IconField,
    InputIcon,
    InputText,
    ConfirmDialog,
    Calendar
  },
  setup() {
    const store = useStore();
    const searchQuery = ref<string>('');
    const searchDate = ref<Date>(new Date());
    const consumers = ref<Consumer[]>([]);
    const devicesMap = ref<Record<string, Device>>({});
    const transformedConsumers = ref<any[]>([]);
    const selectedProduct = ref<any[]>([]);
    const overlayChips = ref<string[]>([]);
    const overlayPanel = ref<InstanceType<typeof OverlayPanel> | null>(null);

    const selectedSite = computed(() => store.state.site.selectedSite);

    const confirm = useConfirm();
    const toast = useToast();

    const stockClass = (data: any) => {
      return [
        'border-circle w-2rem h-2rem inline-flex font-bold justify-content-center align-items-center text-sm',
        'text-rating-' + data.indication,
        'bg-rating-' + data.indication
      ];
    };

    const convertUTCToLocal = (dateString: string): Date => {
      const date = new Date(dateString + 'Z'); // Ensure the date is parsed as UTC
      const localDate = new Date(date.toLocaleString()); // Convert to local timezone
      return localDate;
    };

    const normalizeDate = (date: Date): Date => {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    };

    const filteredConsumers = computed(() => {
      const normalizedSearchDate = normalizeDate(searchDate.value);

      const filtered = consumers.value.filter(consumer => {
        const createdDate = convertUTCToLocal(consumer.created);
        const normalizedCreatedDate = normalizeDate(createdDate);
        return normalizedCreatedDate >= normalizedSearchDate;
      });

      // Define the type for the grouped consumers object
      const groupedConsumers: Record<string, Consumer> = filtered.reduce((acc: Record<string, Consumer>, consumer: Consumer) => {
        const key = `${consumer.identifier}_${consumer.deviceID}`;
        const existingConsumer = acc[key];

        if (existingConsumer) {
          const timeDifference = Math.abs(new Date(consumer.created).getTime() - new Date(existingConsumer.created).getTime());
          if (timeDifference <= 3000) { // 3 seconds
            if (new Date(consumer.created) > new Date(existingConsumer.created)) {
              acc[key] = consumer; // Replace with the more recent consumer
            }
          } else {
            acc[`${key}_${consumer.consumerID}`] = consumer; // Different creation times, treat as separate
          }
        } else {
          acc[key] = consumer;
        }

        return acc;
      }, {});

      const uniqueConsumers = Object.values(groupedConsumers);

      if (!searchQuery.value) {
        return uniqueConsumers;
      }

      const query = searchQuery.value.toLowerCase();
      return uniqueConsumers.filter(consumer => {
        const ptId = consumer.identifier || '';
        return ptId.toLowerCase().includes(query);
      });
    });

    const showOverlay = (event: Event, chips: string[]) => {
      overlayChips.value = chips;
      overlayPanel.value?.toggle(event);
    };

    const hideOverlay = () => {
      overlayPanel.value?.hide();
    };

    const calculateIndication = (consumer: any) => {
      let indication = 0;
      indication += consumer.symptoms.length > 0 ? consumer.symptoms.length + 2 : 0;
      if (consumer.digUser === 'Yes') {
        indication += 2;
      }
      if (consumer.learnMore === 'Yes') {
        indication += 1;
      }
      return indication;
    };

    const convertKeyName = (key: string) => {
      return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()).split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };

    const formatDateTime = (dateString: string) => {
      const date = convertUTCToLocal(dateString); // Convert to local timezone
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
      const now = new Date();
      const isToday = date.toDateString() === now.toDateString();
      const formattedDate = new Intl.DateTimeFormat(undefined, options).format(date);
      return isToday ? `Today, ${formattedDate.split(', ')[1]}` : formattedDate;
    };

    const transformConsumersData = (consumers: any[]) => {
      if (!Array.isArray(consumers)) return [];
      return consumers.map(consumer => {
        if (!consumer.response) {
          console.error('Consumer has no response field:', consumer);
          return null;
        }

        let response;
        try {
          response = JSON.parse(consumer.response);
        } catch (e) {
          console.error('Error parsing consumer.Response:', consumer.response, e);
          return null;
        }

        const symptoms: string[] = [];
        for (const [key, value] of Object.entries(response.symptoms)) {
          if (value === true) symptoms.push(convertKeyName(key));
        }

        const deviceInfo = devicesMap.value[consumer.deviceID];
        const deviceDisplay = deviceInfo ? (deviceInfo.alias || deviceInfo.description || 'unknown') : 'unknown';

        return {
          consumerID: consumer.consumerID, // Include ConsumerID
          ptId: consumer.identifier,
          datetime: formatDateTime(consumer.created),
          deviceInfo: deviceDisplay,
          symptoms: symptoms,
          digUser: response.digUser ? 'Yes' : 'No',
          learnMore: response.learnMore ? 'Yes' : 'No',
          indication: calculateIndication({
            symptoms: symptoms,
            digUser: response.digUser ? 'Yes' : 'No',
            learnMore: response.learnMore ? 'Yes' : 'No'
          })
        };
      }).filter(item => item !== null);
    };

    const fetchConsumers = async () => {
      if (selectedSite.value) {
        const endpoint = '/api/Consumers/GetBySite';
        const requestBody = { SiteID: selectedSite.value.value };

        const consumerList = await apiCall(endpoint, requestBody);
        if (consumerList) {
          consumers.value = consumerList;
          transformedConsumers.value = transformConsumersData(consumerList);
        } else {
          console.error('Failed to fetch consumers');
        }
      }
    };

    const fetchDevices = async () => {
      if (selectedSite.value) {
        const endpoint = '/api/Devices/GetDevicesBySiteID';
        const requestBody = { SiteID: selectedSite.value.value };

        const deviceList = await apiCall(endpoint, requestBody);
        if (deviceList) {
          devicesMap.value = deviceList.reduce((map: Record<string, Device>, device: Device) => {
            map[device.deviceID] = device;
            return map;
          }, {});
        } else {
          console.error('Failed to fetch devices');
        }
      }
    };

    const confirmDeleteSelected = () => {
      confirm.require({
        message: 'Do you want to delete the selected records?',
        header: 'Confirm Deletion',
        icon: 'pi pi-info-circle',
        acceptLabel: 'Delete',
        rejectLabel: 'Cancel',
        accept: async () => {
          try {
            const consumerIDs = selectedProduct.value.map((consumer: any) => consumer.consumerID);
            await apiCall('/api/Consumers/Delete', { ConsumerIDs: consumerIDs });
            toast.add({ severity: 'success', summary: 'Success', detail: 'Records deleted', life: 3000 });
            selectedProduct.value = []; // Clear selection after deletion
            await fetchConsumers(); // Refresh the consumers data
          } catch (error) {
            toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete records', life: 3000 });
          }
        },
        reject: () => {
          toast.add({ severity: 'info', summary: 'Cancelled', detail: 'Deletion cancelled', life: 3000 });
        }
      });
    };

    const formattedSearchDate = computed(() => {
      const today = new Date();
      const userSearchString = searchQuery.value === '' ? '' : ' matching your search query';
      return searchDate.value.toDateString() === today.toDateString() ? 'today' + userSearchString + '.' : 'since ' + searchDate.value.toLocaleDateString() + userSearchString + '.';
    });

    watch(
      filteredConsumers,
      newVal => {
        transformedConsumers.value = transformConsumersData(newVal);
      },
      { immediate: true }
    );

    watch(
      selectedSite,
      async (newSite) => {
        if (newSite) {
          await fetchDevices(); // Fetch devices when the site changes
          await fetchConsumers(); // Fetch consumers when the site changes
        }
      },
      { immediate: true }
    );

    return {
      searchQuery,
      resultsSinceDate: searchDate,
      transformedConsumers,
      filteredConsumers,
      overlayChips,
      stockClass,
      showOverlay,
      hideOverlay,
      calculateIndication,
      convertKeyName,
      formatDateTime,
      transformConsumersData,
      overlayPanel,
      selectedProduct,
      confirmDeleteSelected,
      fetchConsumers,
      fetchDevices,
      formattedSearchDate
    };
  }
});
</script>

<style scoped>
.chips-horizontal-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.green-chip {
  background-color: #179eb6;
  color: white;
}

.chips-vertical-container {
  display: flex;
  flex-direction: column;
}

.vertical-chip {
  margin-bottom: 0.5rem;
}

.bg-rating-0 {
  background-color: #ff8f8f;
}

.bg-rating-1 {
  background-color: #ff9783;
}

.bg-rating-2 {
  background-color: #ffa177;
}

.bg-rating-3 {
  background-color: #ffac6d;
}

.bg-rating-4 {
  background-color: #fab864;
}

.bg-rating-5 {
  background-color: #f0c45e;
}

.bg-rating-6 {
  background-color: #e3d15d;
}

.bg-rating-7 {
  background-color: #d4dd61;
}

.bg-rating-8 {
  background-color: #c1e96b;
}

.bg-rating-9 {
  background-color: #aaf47a;
}

.bg-rating-10 {
  background-color: #8fff8f;
}

.text-rating-0 {
  color: #631818;
}

.text-rating-1 {
  color: #642010;
}

.text-rating-2 {
  color: #632906;
}

.text-rating-3 {
  color: #603100;
}

.text-rating-4 {
  color: #5c3900;
}

.text-rating-5 {
  color: #574100;
}

.bg-rating-6 {
  color: #4f4900;
}

.text-rating-7 {
  color: #475000;
}

.text-rating-8 {
  color: #3c5700;
}

.text-rating-9 {
  color: #2e5d05;
}

.text-rating-10 {
  color: #186318;

}

.component-bg {
  background-color: #1f2937;
}

.rounded-2xl {
  border-radius: 1rem;
}

.borderless-paginator>>>.p-paginator {
  border: none;
}
</style>
