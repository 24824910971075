import { Module } from 'vuex';
import axios from 'axios';

export interface AuthState {
  isAuthenticated: boolean;
  siteID: string | null;
  username: string | null;
}

const state: AuthState = {
  isAuthenticated: !!localStorage.getItem('authToken'),
  siteID: localStorage.getItem('siteID'),
  username: localStorage.getItem('username')
};

const mutations = {
  setAuth(state: AuthState, { status, siteID, username }: { status: boolean; siteID: string | null; username: string | null }) {
    state.isAuthenticated = status;
    if (status) {
      localStorage.setItem('authToken', 'simulated-token');
      localStorage.setItem('siteID', siteID!);
      localStorage.setItem('username', username!);
      state.siteID = siteID;
      state.username = username;
    } else {
      localStorage.removeItem('authToken');
      localStorage.removeItem('siteID');
      localStorage.removeItem('username');
      state.siteID = null;
      state.username = null;
    }
  },
  clearSelectedSite() {
    localStorage.removeItem('selectedSite');
  }
};

const actions = {
  async login({ commit }: any, { username, password }: { username: string; password: string }) {
    try {
      const response = await axios.post('https://nl-ioe-dev.azurewebsites.net/api/Users/login', { username, password });
      const siteID = response.data.siteID;
      commit('setAuth', { status: true, siteID, username });
      return 'Logged in successfully';
    } catch (error) {
      throw new Error('Invalid credentials');
    }
  },
  async register({ commit }: any, { username, password, registrationKey }: { username: string; password: string; registrationKey: string }) {
    try {
      const response = await axios.post('https://nl-ioe-dev.azurewebsites.net/api/Users/register', { username, password, registrationKey });
      const siteID = response.data.siteID;
      commit('setAuth', { status: true, siteID, username });
      return 'Registered successfully';
    } catch (error) {
      throw new Error('Registration failed');
    }
  },
  logout({ commit }: any) {
    commit('setAuth', { status: false, siteID: null, username: null });
    commit('clearSelectedSite');
    commit('site/clearSelectedSite', null, { root: true });
  }
};

const getters = {
  isLoggedIn: (state: AuthState) => state.isAuthenticated,
  siteID: (state: AuthState) => state.siteID,
  username: (state: AuthState) => state.username
};

const auth: Module<AuthState, unknown> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default auth;
