<template>
  <Menubar class="nonrounded">
    <template #start>
      <img alt="Vue logo" src="../assets/digifitLogoSm.png" />
    </template>
    <template #item="{ item, props, hasSubmenu, root }">
      <a v-ripple class="flex align-items-center" v-bind="props.action">
        <span :class="item.icon" />
        <span class="ml-2">{{ item.label }}</span>
        <Badge v-if="item.badge" :class="{ 'ml-auto': !root, 'ml-2': root }" :value="item.badge" />
        <span v-if="item.shortcut" class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{{ item.shortcut }}</span>
        <i v-if="hasSubmenu" :class="['pi pi-angle-down', { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root }]"></i>
      </a>
    </template>
    <template #end>
      <div class="topbar-end mr-3">
        <Button rounded @click="toggleOverlayPanel">
          <Avatar :label="firstLetter" class="mr-2" style="background-color: #ece9fc; color: #2a1261" shape="circle" />
          <span v-if="selectedSiteValue">{{ selectedSiteValue.label }}</span>
        </Button>

        <OverlayPanel ref="overlayPanel">
          <div class="">
            <Menu :model="items" />
          </div>
        </OverlayPanel>

        <Dialog v-model:visible="visible" modal header="Change Site" :style="{ width: '25rem' }">
          <span class="p-text-secondary block mb-5">As a primary site administrator, you can select which site you want to see. Choose one below</span>
          <Dropdown class="my-3" v-model="selectedSiteValue" :options="siteOptions" optionLabel="label" placeholder="Select a Site" @change="onSiteChange" />
          <div class="flex justify-content-end gap-2">
            <Button type="button" label="Cancel" severity="secondary" @click="visible = false"></Button>
          </div>
        </Dialog>
      </div>
    </template>
  </Menubar>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import Menubar from 'primevue/menubar';
import Avatar from 'primevue/avatar';
import OverlayPanel from 'primevue/overlaypanel';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Badge from 'primevue/badge';
import { useStore } from 'vuex';
import apiCall from '../utils/api';

export default defineComponent({
  name: 'NavbarComp',
  components: {
    Menubar,
    Avatar,
    OverlayPanel,
    Button,
    Menu,
    Dialog,
    Dropdown,
    Badge
  },
  setup(props, { emit }) {
    const store = useStore();
    const overlayPanel = ref<InstanceType<typeof OverlayPanel> | null>(null);

    const selectedSite = computed(() => store.state.site.selectedSite);
    const username = computed(() => store.state.auth.username || '');

    const firstLetter = computed(() => {
      if (username.value) {
        return username.value.charAt(0).toUpperCase();
      }
      return '';
    });

    const visible = ref(false);
    const showHome = ref(false);
    const siteOptions = ref<any[]>([]);

    const isSingleSite = computed(() => {
      return siteOptions.value.length <= 1;
    });

    const items = computed(() => [
      {
        label: 'Site',
        items: [
          {
            label: 'Change Site',
            icon: 'pi pi-home',
            command: () => { visible.value = true; },
            disabled: isSingleSite.value
          }
        ]
      },
      {
        label: 'Profile',
        items: [
          {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: () => handleLogout()
          }
        ]
      }
    ]);

    const selectedSiteValue = computed({
      get() {
        return selectedSite.value;
      },
      set(value) {
        store.dispatch('site/updateSelectedSite', value);
        localStorage.setItem('selectedSite', JSON.stringify(value)); // Save to local storage
        emit('site-changed', value); // Emit event when site changes
      }
    });

    const fetchSites = async () => {
      try {
        const endpoint = '/api/Sites/GetBySiteID'; // Adjust this endpoint if necessary
        const siteID = store.getters['auth/siteID']; // Ensure siteID is retrieved correctly
        const requestBody = { SiteID: siteID };
        const response = await apiCall(endpoint, requestBody);
        if (response) {
          siteOptions.value = response.map((site: any) => ({ label: site.siteName, value: site.siteID }));
          const storedSite = localStorage.getItem('selectedSite');
          if (storedSite) {
            const parsedSite = JSON.parse(storedSite);
            store.dispatch('site/updateSelectedSite', parsedSite);
          } else if (siteOptions.value.length === 1) {
            const singleSite = siteOptions.value[0];
            store.dispatch('site/updateSelectedSite', singleSite);
            localStorage.setItem('selectedSite', JSON.stringify(singleSite));
          } else if (siteOptions.value.length > 1) {
            // No site is stored, set to the first option if multiple sites are available
            const firstSite = siteOptions.value[0];
            store.dispatch('site/updateSelectedSite', firstSite);
            localStorage.setItem('selectedSite', JSON.stringify(firstSite));
            visible.value = true;
          } else {
            // No site is stored, open the dialog to select a site
            visible.value = true;
          }
        } else {
          console.error('Failed to fetch sites');
        }
      } catch (error) {
        console.error('Error fetching sites:', error);
      }
    };

    const onSiteChange = (event: any) => {
      const siteID = event.value;
      store.dispatch('site/updateSelectedSite', siteID);
      localStorage.setItem('selectedSite', JSON.stringify(siteID)); // Save to local storage
      emit('site-changed', siteID); // Emit event when site changes
      visible.value = false;
    };

    const handleLogout = () => {
      store.dispatch('auth/logout').then(() => {
        window.location.href = '/login';
      });
    };

    const toggleOverlayPanel = (event: Event) => {
      overlayPanel.value?.toggle(event);
    };

    onMounted(() => {
      fetchSites();
    });

    watch(selectedSite, (newSite) => {
      if (!newSite && siteOptions.value.length > 1) {
        visible.value = true;
      }
    }, { immediate: true });

    return {
      firstLetter,
      visible,
      showHome,
      items,
      siteOptions,
      selectedSiteValue,
      onSiteChange,
      handleLogout,
      toggleOverlayPanel,
      overlayPanel,
      isSingleSite
    };
  }
});
</script>

<style scoped>
.topbar-end {
  display: flex;
  align-items: center;
}
.nonrounded {
  border-radius: 0;
}
</style>
