import { Module } from 'vuex';

export interface SiteState {
  selectedSite: { label: string, value: string } | null;
}

const safeParseJSON = (value: string | null) => {
  try {
    return value ? JSON.parse(value) : null;
  } catch (e) {
    console.error('Failed to parse JSON from localStorage:', e);
    return null;
  }
};

const state: SiteState = {
  selectedSite: safeParseJSON(localStorage.getItem('selectedSite'))
};

const mutations = {
  setSelectedSite(state: SiteState, site: { label: string, value: string }) {
    state.selectedSite = site;
    localStorage.setItem('selectedSite', JSON.stringify(site));
  },
  clearSelectedSite(state: SiteState) {
    state.selectedSite = null;
    localStorage.removeItem('selectedSite');
  }
};

const actions = {
  updateSelectedSite({ commit }: any, site: { label: string, value: string }) {
    commit('setSelectedSite', site);
  }
};

const getters = {
  selectedSite: (state: SiteState) => state.selectedSite,
};

const site: Module<SiteState, unknown> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default site;
