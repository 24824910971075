// src/utils/api.ts

import axios from 'axios';
import config from '../config'; // Import the configuration

interface ApiCallResponse {
  status: number;
  data: any;
  statusText: string;
}

const apiCall = async (endpoint: string, requestBody: any): Promise<any | null> => {
  const apiUrl = `${config.API_BASE_URL}${endpoint}`;

  try {
    const response: ApiCallResponse = await axios.post(apiUrl, JSON.stringify(requestBody), {
      headers: { 'Content-Type': 'application/json' }
    });


    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      console.error('Error in API call:', response.status, response.statusText);
      return null;
    }
  } catch (error) {
    console.error('Error in API call:', error);
    return null;
  }
};

export default apiCall;
