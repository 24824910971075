<template>
  <div class="xl-container">
    <div class="flex-1 rounded-2xl px-3 pt-3 component-bg borderless-paginator">
      <div v-if="$props.devices.length > 0">
        <DataTable :value="$props.devices" tableStyle="min-width: 50rem" :rows="8" :paginator="true" striped-rows>
        <Column field="description" header="Device">
          <template #body="slotProps">
            <div class="text-center">
              <img src="../assets/meta-quest-3.png" alt="Quest 3" class="device-image" />
              <div class="image-text">{{ slotProps.data.description }}</div>
            </div>
          </template>
        </Column>
        <Column field="alias" header="Nickname">
          <template #body="slotProps">
            <input type="text" class="mt-2 p-inputtext" v-model="slotProps.data.alias"
              :disabled="!slotProps.data.isEditing" />
          </template>
        </Column>
        <Column field="volume" header="Default Volume">
          <template #body="slotProps">
            <Knob v-model="slotProps.data.volume" :min="1" :max="15" :disabled="!slotProps.data.isEditing" />
          </template>
        </Column>
        <Column field="actions" header="">
          <template #body="slotProps">
            <Button v-if="slotProps.data.isEditing" icon="pi pi-check" rounded
              @click="saveDevice(slotProps.data)"></Button>
            <Button v-else icon="pi pi-pencil" rounded @click="editDevice(slotProps.data)"></Button>
          </template>
        </Column>
      </DataTable>
      </div>
      <h2 v-else>
        No devices allocated to this site.
      </h2>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Knob from 'primevue/knob';
import Button from 'primevue/button';
import apiCall from '../utils/api';

interface Device {
  deviceID: string;
  alias: string | null;
  volume: number;
  isEditing: boolean;
  configurationSettings: string;
}

export default defineComponent({
  name: 'DevicesPage',
  props: {
    devices: {
      type: Array as () => Device[],
      required: true
    }
  },
  components: {
    DataTable,
    Column,
    Knob,
    Button
  },
  setup() {

    const editDevice = (device: Device) => {
      device.isEditing = true;
    };

    const saveDevice = async (device: Device) => {
      device.isEditing = false;
      try {
        const configSettings = JSON.parse(device.configurationSettings);
        configSettings.DefaultVolume = device.volume;

        const endpoint = '/api/Devices/UpdateDeviceByDeviceID';
        const requestBody = {
          DeviceID: device.deviceID,
          Alias: device.alias,
          ConfigurationSettings: JSON.stringify(configSettings)
        };
        const response = await apiCall(endpoint, requestBody);
      } catch (error) {
        console.error('Error saving device:', error);
      }
    };

    return {
      editDevice,
      saveDevice
    };
  }
});
</script>

<style scoped>


.component-bg {
  background-color: #1f2937;
}

.rounded-2xl {
  border-radius: 1rem;
}

.borderless-paginator>>>.p-paginator {
  border: none;
}

.text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.device-image {
  margin-bottom: 0.5rem;
  width: 130px;
}

.image-text {
  text-align: center;
}
</style>
