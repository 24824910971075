<template>
  <div>
    <NavbarComp @update-show-home="updateShowHome" />
    <div class="layout-content-wrapper">
      <div class="grid">
        <div class="col-12">

          <div class="xl-container">
            <Card>
              <template #content>
                <TabView>
                  <TabPanel>
                    <template #header>
                      <div class="flex align-items-center gap-2">
                        <i class="pi pi-users rounded"></i>
                        <div>Users</div>
                      </div>
                    </template>

                    <CustomDataTable />
                  </TabPanel>
                  <TabPanel>
                    <template #header>
                      <div class="flex align-items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
                          fill="#ffffff">
                          <path
                            d="M300-252q-65 0-110.5-45.5T144-408v-146q0-45 27-81t72-47q58-14 117.5-20t119.5-6q60 0 119.5 6T717-682q45 11 72 47t27 81v146q0 65-45.5 110.5T660-252h-37q-15 0-29.5-3t-28.5-8l-54-22q-15-6-31-6t-31 6l-54 22q-14 5-28.5 8t-29.5 3h-37Zm0-72h37q8 0 15.5-1.5T368-330q28-11 55.5-21.5T480-362q29 0 57 10.5t55 21.5q8 3 15.5 4.5T623-324h37q35 0 59.5-24.5T744-408v-146q0-20-12.5-36.5T699-612q-54-14-108.5-19T480-636q-56 0-110.5 5.5T261-612q-20 5-32.5 21.5T216-554v146q0 35 24.5 59.5T300-324ZM48-396v-168h48v168H48Zm816 0v-168h48v168h-48Zm-384-84Z" />
                        </svg>
                        <div>Devices</div>
                      </div>
                    </template>
                    <DevicesPage :devices="devices" />
                  </TabPanel>
                </TabView>
              </template>

            </Card>

          </div>

        </div>
      </div>
    </div>
    <FooterComp />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import CustomDataTable from '../components/CustomDataTable.vue';
import FooterComp from '../components/FooterComp.vue';
import NavbarComp from '../components/NavbarComp.vue';
import DevicesPage from './DevicesPage.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Card from 'primevue/card';
import apiCall from '../utils/api';

interface Device {
  deviceID: string;
  alias: string | null;
  volume: number;
  isEditing: boolean;
  configurationSettings: string;
}

export default defineComponent({
  name: 'HomePage',
  components: {
    CustomDataTable,
    FooterComp,
    NavbarComp,
    DevicesPage,
    TabView,
    TabPanel,
    Card
  },
  setup() {
    const store = useStore();
    const showHome = ref(true);
    const devices = ref<Device[]>([]);

    const selectedSite = computed(() => store.state.site.selectedSite);

    const value = ref('One-Way');
    const options = ref(['One-Way', 'Return']);

    const updateShowHome = (value: boolean) => {
      showHome.value = value;
    };

    const fetchDevices = async () => {
      if (!selectedSite.value) {
        console.error('No selected site ID');
        return;
      }

      try {
        const endpoint = '/api/Devices/GetDevicesBySiteID'; // Adjust this endpoint if necessary
        const requestBody = { SiteID: selectedSite.value.value };
        const response = await apiCall(endpoint, requestBody);

        if (response && response.data) {
          devices.value = response.data.map((device: any) => {
            const configSettings = JSON.parse(device.configurationSettings);
            return {
              ...device,
              volume: configSettings.DefaultVolume ?? 0,
              isEditing: false
            };
          });
        } else if (Array.isArray(response)) {
          devices.value = response.map((device: any) => {
            const configSettings = JSON.parse(device.configurationSettings);
            return {
              ...device,
              volume: configSettings.DefaultVolume ?? 0,
              isEditing: false
            };
          });
        } else {
          console.error('No valid data received from API');
        }
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };

    onMounted(() => {
      if (selectedSite.value) {
        fetchDevices();
      } else {
        console.error('No selected site ID on mount');
      }
    });

    watch(selectedSite, (newSite, oldSite) => {
      if (newSite !== oldSite) {
        fetchDevices();
      }
    });

    return {
      showHome,
      updateShowHome,
      devices,
      value,
      options
    };
  }
});
</script>

<style scoped>
.xl-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
}

.layout-content-wrapper {
  padding: 1rem;
}

.justify-center {
  justify-content: center;
}

.p-highlight svg {
  fill: rgb(129, 140, 248)
}

/* .container-bg {
  background-color: rgb(28, 37, 50)
} */
</style>
