<template>
    <div>
      <form @submit.prevent="handleSubmit">
        <InputGroup class="my-3">
        <InputGroupAddon>
            <i class="pi pi-user"></i>
        </InputGroupAddon>
        <InputText v-model="credentials.username" placeholder="Username" />
        </InputGroup>
        <InputGroup class="my-3">
        <InputGroupAddon>
            <i class="pi pi-lock"></i>
        </InputGroupAddon>
        <Password
            type="password"
            v-model="credentials.password"
            :feedback="false"
            placeholder="Password"
            :toggleMask="true"
          />
        </InputGroup>

        
        <p v-if="error" class="error-message my-3">{{ error }}</p>
          
        <Button type="submit" label="Login" class="p-mt-2" />
      </form>
      <p class="toggle-link" @click="$emit('toggle')"><span class="link">Create Account</span></p>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref } from 'vue';
  import { useStore } from 'vuex';
  import InputText from 'primevue/inputtext';
  import Password from 'primevue/password';
  import Button from 'primevue/button';
  import InputGroup from 'primevue/inputgroup';
  import InputGroupAddon from 'primevue/inputgroupaddon';
  
  export default defineComponent({
    name: 'LoginWidget',
    components: {
      InputText,
      Password,
      Button,
      InputGroup,
      InputGroupAddon
    },
    setup(_, { emit }) {
      const store = useStore();
      const credentials = ref({ username: '', password: '' });
      const error = ref<string>('');
  
      const handleSubmit = async () => {
        try {
          await store.dispatch('auth/login', {
            username: credentials.value.username,
            password: credentials.value.password,
          });
          (window as any).location = '/home'; // Redirect on successful login
        } catch (err: any) {
          error.value = (err as Error).message || 'Login failed'; // Handle error, show message to user
        }
      };
  
      return {
        credentials,
        error,
        handleSubmit,
        emit,
      };
    },
  });
  </script>
  
  <style scoped>
  .p-field {
    margin-bottom: 1rem;
  }
  
  .error-message {
    color: red;
    text-align: center;
  }
  
  .toggle-link {
    color: #f6f6f6;
    text-align: center;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .link {
    text-decoration: underline;
  }
  </style>
  